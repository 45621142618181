<template>
    <mercur-card>
        <h2 class="mt-1 font-weight-normal">Artwork check</h2>
        <div class="row">
            <div class="col-12">
                <div class="form-field" v-if="value">
                    <span class="mr-3 d-inline-flex align-items-center" v-for="(grade) in grades" :key="`grade-checkbox-${grade}`">
                        <mercur-checkbox v-model="value" :value="grade" :id="`grade-checkbox-${grade}`">{{ grade }}</mercur-checkbox>
                    </span>
                </div>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'AgentArtworkRoles',
    props: ['value'],
    data () {
        return {
            values: this.value,
            grades: CONFIG.ARTWORK_GRADES,
        }
    },
}
</script>
