<template>
    <div class="pb-5">
        <v-title v-if="Object.keys(response).length" :title="`${response.data.firstName} ${response.data.lastName} - Agents`"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Edit agent</h1>
                </div>
            </div>
        </title-box>
        <form @submit.prevent="submitForm" v-if="this.form && hasPermission('MerchantCentral/updateAccount')">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <template v-if="!isFetchingResources">
                            <agent-personal-information class="mt-n4 mb-4" :form="form" :$v="$v"></agent-personal-information>

                            <agent-roles class="mb-4" :form="form"></agent-roles>

                            <agent-artwork-roles class="mb-4" v-model="form.accountSettings.artworkGrades"></agent-artwork-roles>

                            <mercur-card class="mb-4">
                                <h2 class="mt-1 font-weight-normal">Admin actions</h2>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex align-items-center">
                                            <mercur-button class="btn btn-yellow mr-3" :disabled="isResetting" @click="resetPassword">Reset password</mercur-button>
                                            <span class="d-flex align-items-center">
                                                <mercur-checkbox v-model="isRevoked" id="disableAccount">Disable account</mercur-checkbox>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </mercur-card>
                            <div class="text-right">
                                <mercur-button class="btn" to="/agents">Cancel</mercur-button>
                                <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Save changes</mercur-button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </form>
        <p v-else>Not allowed to update account</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ResourcesView from '@/views/ResourcesView'

import AgentPersonalInformation from '@/components/elements/agents/PersonalInformation'
import AgentRoles from '@/components/elements/agents/Roles'
import AgentArtworkRoles from '@/components/elements/agents/ArtworkRoles'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
    name: 'EditAgent',
    mixins: [ResourcesView, FormMixin],
    components: { AgentPersonalInformation, AgentRoles, AgentArtworkRoles },
    data () {
        return {
            url: CONFIG.API.ROUTES.AGENTS.GET.replace('{agentId}', this.$route.params.agentId),
            form: {
                accountSettings: {},
                roles: {},
            },
            isRevoked: false,
            isResetting: false,
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
            },
            gender: {
                required,
            },
            language: {
                required,
            },
            countryCode: {
                required,
            },
        },
    },
    watch: {
        response () {
            this.form = this.response.data
            if (this.form.accountSettings && Array.isArray(this.form.accountSettings)) {
                this.form.accountSettings = {}
            }
            if (!this.form.accountSettings.artworkGrades || (this.form.accountSettings.artworkGrades && !Array.isArray(this.form.accountSettings.artworkGrades))) {
                this.form.accountSettings.artworkGrades = []
            }
            if (this.form.dateRevoked) {
                this.isRevoked = true
            }
        },
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.AGENTS.UPDATE.replace('{agentId}', this.$route.params.agentId)
        },
        breadCrumbId () {
            if (!this.response.data) {
                return
            }
            return `${this.response.data.firstName} ${this.response.data.lastName}`
        },
    },
    methods: {
        ...mapActions('auth', [ 'recoverPassword' ]),
        submitForm () {
            if (!this.form.dateRevoked && this.isRevoked) {
                this.form.dateRevoked = moment().format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.form.dateRevoked && !this.isRevoked) {
                this.form.dateRevoked = null
            }

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Agent was updated',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'AgentsWrapper',
                    })
                }, 2000)
            })
        },
        resetPassword () {
            this.isResetting = true

            this.recoverPassword({ username: this.form.username }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Password reset email was sent',
                    type: 'success',
                })
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                })
            }).finally(() => {
                this.isResetting = false
            })
        },
    },
}
</script>
