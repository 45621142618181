<template>
    <mercur-card>
        <h2 class="mt-1 font-weight-normal">Roles</h2>
        <div class="row">
            <div class="col-12">
                <mercur-spinner v-if="isFetchingResources"></mercur-spinner>
                <pretty-select
                    v-if="form && items.length"
                    label="roleName"
                    placeholder="Role*"
                    :options="items"
                    v-model="form.roles.roleIds"
                    :reduce="role => role.roleId"
                    :multiple="true"
                ></pretty-select>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import ResourcesView from '@/views/ResourcesView'
import PrettySelect from '@/components/utils/PrettySelect'
export default {
    name: 'AgentRoles',
    components: { PrettySelect },
    mixins: [ResourcesView],
    data () {
        return {
            url: CONFIG.API.ROUTES.ROLES.OVERVIEW,
            isNotApplicationBound: true,
        }
    },
    props: {
        form: {
            type: Object,
        },
    },
    computed: {
        route () {
            return {
                name: 'EditAgentPermissions',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
}
</script>
