<template>
    <mercur-card>
        <h2 class="mt-1 font-weight-normal">Personal information</h2>
        <div class="row">
            <div class="col-12">
                <div class="mb-3" :class="getValidationClass($v, 'gender')">
                    <p class="mb-1">Gender</p>
                    <span class="ml-n1 mr-2">
                        <input type="radio" id="male" value="MALE" v-model="form.gender">
                        <label for="male">Mr.</label>
                    </span>
                    <input type="radio" id="female" value="FEMALE" v-model="form.gender">
                    <label for="female">Mrs.</label>
                    <span class="form-error" v-if="!$v.form.gender.required">Gender is required</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mercur-input v-model="form.firstName" :class="getValidationClass($v, 'firstName')">
                    First name
                    <template #note>
                        <span class="form-error" v-if="!$v.form.firstName.required">First name is required</span>
                    </template>
                </mercur-input>
            </div>
            <div class="col-6">
                <mercur-input v-model="form.lastName" :class="getValidationClass($v, 'lastName')">
                    Last name
                    <template #note>
                        <span class="form-error" v-if="!$v.form.lastName.required">Last name is required</span>
                    </template>
                </mercur-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mercur-input type="email" v-model="form.email" :class="getValidationClass($v, 'email')">
                    E-mail address
                    <template #note>
                        <span class="form-error" v-if="!$v.form.lastName.required">Email is required</span>
                        <span v-else>Password will be sent to this address</span>
                    </template>
                </mercur-input>
            </div>
            <div class="col-6">
                <language-selector v-model="form.language" :$v="$v.form.language" />
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <country-selector class="mb-3" v-model="form.countryCode" :$v="$v.form.countryCode" reducer="countryShortCode" :is-lower-case="true"></country-selector>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CountrySelector from '../CountrySelector'
import LanguageSelector from '../LanguageSelector'

export default {
    name: 'AgentPersonalInformation',
    components: { CountrySelector, LanguageSelector },
    mixins: [FormMixin],
    props: {
        form: {
            type: Object,
        },
        $v: {
            type: Object,
        },
    },
}
</script>
